import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Moment from 'react-moment';
import { Alert, Button, Nav, NavItem, NavLink, Card, ListGroup, ListGroupItem, Badge, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { useServerTasks } from 'lib/ServerTasksProvider';
import t, { language } from 'lib/translate';
import { DOCUMENT_TYPE_EMOJI } from 'config/Constants';

const MIN_DELETE_BUTTON_AGE = 30 * 60 * 1000; // 30 minutes

function TaskItem({ task, isWorking, onDelete, href }) {
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const spinnerColor = { preprocessing: 'primary', 'doc-generation': 'primary' }[task.state] ?? 'secondary';
  const stateColor =
    {
      preprocessing: 'primary',
      'doc-generation': 'primary',
      'doc-generation-failed': 'danger',
      'preprocessing-failed': 'danger',
      succeeded: 'success',
    }[task.state] ?? 'secondary';

  let label;

  if (href) {
    label = (
      <a href={href} target="_blank" rel="noreferrer">
        {DOCUMENT_TYPE_EMOJI[task.documentType]} {task.file.name}
      </a>
    );
  } else {
    label = (
      <span>
        {DOCUMENT_TYPE_EMOJI[task.documentType]} {task.file.name}
      </span>
    );
  }

  let deleteButton;
  let errorMessage;

  if (['doc-generation-failed', 'preprocessing-failed'].includes(task.state)) {
    if (task.message) {
      const message = showFullMessage ? task.message : task.message.split('\n')[0];

      const handleErrorClick = () => {
        setShowFullMessage(true);
      };

      errorMessage = (
        <Alert fade={false} className="mt-2 mb-1" color="danger" onClick={handleErrorClick}>
          <pre className="mb-0">{message}</pre>
        </Alert>
      );
    }

    if (Date.now() - new Date(task.updatedAt) > MIN_DELETE_BUTTON_AGE) {
      const handleDeleteClick = () => {
        setIsDeleting(true);
        onDelete(task);
      };

      let buttonContent;

      if (isDeleting) {
        buttonContent = <Spinner size="sm" />;
      } else {
        buttonContent = <FaTrash />;
      }

      deleteButton = (
        <div className="ml-auto">
          <Button className="ml-1" disabled={isDeleting} onClick={handleDeleteClick}>
            {buttonContent}
          </Button>
        </div>
      );
    }
  }

  return (
    <>
      <ListGroupItem>
        <div className="d-flex align-items-center align-content-center">
          <div>
            {isWorking ? <Spinner size="sm" color={spinnerColor} /> : null} {label}{' '}
            <Badge color={stateColor}>{task.state}</Badge>{' '}
            {task.documentProcessor === 'v2' ? (
              <>
                <Badge color="info">new recognition</Badge>{' '}
              </>
            ) : null}
            <Moment fromNow locale={language}>
              {task.updatedAt}
            </Moment>
          </div>
          {deleteButton}
        </div>
        {errorMessage}
      </ListGroupItem>
    </>
  );
}

TaskItem.propTypes = {
  task: PropTypes.object.isRequired,
  isWorking: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  href: PropTypes.string,
};

function TabContents({ selectedTab, tasksProvider, loading, activeTasks, failedTasks, doneTasks }) {
  const params = useParams();
  const dossierId = parseInt(params.dossierId, 10);
  const periodId = parseInt(params.periodId, 10);

  function handleDeleteTask(task) {
    tasksProvider.delete(task.id);
  }

  if (loading) {
    return (
      <div className="m-3">
        <Spinner />
      </div>
    );
  }

  if (selectedTab === 'processing') {
    if (failedTasks.length === 0 && activeTasks.length === 0) {
      return (
        <Alert fade={false} color="info" className="m-3">
          {t('No documents are currently being generated')}
        </Alert>
      );
    }

    return (
      <ListGroup flush>
        {activeTasks.map((task) => {
          return <TaskItem key={task.id} task={task} isWorking={true} onDelete={handleDeleteTask} />;
        })}

        {failedTasks.map((task) => {
          return <TaskItem key={task.id} task={task} isWorking={false} onDelete={handleDeleteTask} />;
        })}
      </ListGroup>
    );
  } else if (selectedTab === 'done') {
    if (doneTasks.length === 0) {
      return (
        <Alert fade={false} color="info" className="m-3">
          {t('All generated documents have been booked')}
        </Alert>
      );
    }

    return (
      <ListGroup flush>
        {doneTasks.map((task) => {
          let href;

          if (task.documentType === 'accountingDocument') {
            href = `/dossier/${dossierId}/${periodId}/edit/${task.accountingDocumentId}`;
          } else {
            href = `/dossier/${dossierId}/${periodId}/banking-documents/${task.bankingDocumentId}`;
          }

          return <TaskItem key={task.id} task={task} isWorking={false} href={href} onDelete={handleDeleteTask} />;
        })}
      </ListGroup>
    );
  }

  return null;
}

TabContents.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  tasksProvider: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  activeTasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  failedTasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  doneTasks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const ServerTasks = observer(() => {
  const params = useParams();
  const dossierId = parseInt(params.dossierId, 10);
  const tasksProvider = useServerTasks(dossierId);
  const [selectedTab, setSelectedTab] = useState('processing');

  return (
    <>
      <h2 className="mt-4">
        <span>
          {t('Document generation')} ({tasksProvider.activeTasks.length})
        </span>
      </h2>

      <Nav tabs className="ml-1">
        <NavItem>
          <NavLink
            tag="span"
            style={{ cursor: 'pointer' }}
            onClick={() => setSelectedTab('processing')}
            active={selectedTab === 'processing'}
          >
            {t('Processing')} ({tasksProvider.activeTasks.length + tasksProvider.failedTasks.length})
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag="span"
            style={{ cursor: 'pointer' }}
            onClick={() => setSelectedTab('done')}
            active={selectedTab === 'done'}
          >
            {t('Generated')} ({tasksProvider.doneTasks.length})
          </NavLink>
        </NavItem>
      </Nav>
      <Card>
        <TabContents
          selectedTab={selectedTab}
          tasksProvider={tasksProvider}
          activeTasks={tasksProvider.activeTasks}
          failedTasks={tasksProvider.failedTasks}
          doneTasks={tasksProvider.doneTasks}
          loading={!tasksProvider.isReady}
        />
      </Card>
    </>
  );
});

export default ServerTasks;
